<template>
  <v-form ref="form" lazy-validation>
    <v-container class="ma-0 pa-0" fluid>
      <v-data-table
        id="partyRelationships"
        :height="tableSize"
        :headers="headers"
        :items="currentPartyRelationships"
        :footer-props="{'items-per-page-options': pageSizes}"
        :items-per-page="pageSize"
        :loading="loading"
        item-key="id"
        dense
        fixed-header
        fixed-footer
        :search.sync="search"
        v-model="selected">
        <template v-slot:top>
          <v-toolbar flat style="cursor: default;">
            <v-toolbar-title class="ml-2 title">
              <v-icon left>mdi-arrow-left-right</v-icon> Relationships
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-select
              style="max-width: 400px"
              :items="partyRelationshipTypes"
              v-model="selectedRelationshipFilter"
              :menu-props="{ offsetY: true }"
              @input="clearSelectedPartyFilter()"
              item-text="name"
              item-value="id"
              label="Filter By Relationship Type"
              dense
              outlined
              clearable
              hide-details>
            </v-select>
            <v-select
              class="mx-4"
              style="max-width: 400px"
              :items="uniquePartyTypes"
              v-model="selectedPartyFilter"
              :menu-props="{ offsetY: true }"
              @input="clearSelectedRelationshipFilter()"
              item-text="name"
              item-value="id"
              label="Filter By Party Type"
              dense
              outlined
              clearable
              hide-details>
            </v-select>
            <v-spacer></v-spacer>
            <v-btn
              v-if="hasWriteAccess"
              id="newRelationshipBtn"
              color='primary'
              dark
              @click="createNewRelationship()">
              Add Relationship
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.from_party`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <router-link @click.native="$router.go()" :to="{ name: 'Parties Edit', query: { id: item.from_party.id } }"><span v-on="on">
                {{ formatAttribute(item, "from") }} {{ item.from_party.name }}
              </span></router-link>
            </template>
            <span>{{item.from_party.party_type.name}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.to_party`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <router-link @click.native="$router.go()" :to="{ name: 'Parties Edit', query: { id: item.to_party.id } }"><span v-on="on">
                {{ formatAttribute(item, "to") }}  {{ item.to_party.name }}
              </span></router-link>
            </template>
            <span>{{item.to_party.party_type.name}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.relationship_type.from_rel.name`]="{ item }">
          <div style="display: grid; grid-auto-rows: auto; padding-top: 2px; padding-bottom: 2px">
           <span>{{ item.relationship_type.from_rel.name }}<v-icon right small>mdi-arrow-right</v-icon></span>
           <span><v-icon left small>mdi-arrow-left</v-icon>{{ item.relationship_type.to_rel.name }}</span>
          </div>
        </template>

        <template v-slot:[`item.start_dt`]="{ item }">
          <span v-if="item.start_dt != null">
            {{moment(item.start_dt).format('YYYY-MM-DD')}}
          </span>
        </template>
        <template v-slot:[`item.end_dt`]="{ item }">
          <span v-if="item.end_dt != null">
            {{moment(item.end_dt).format('YYYY-MM-DD')}}
          </span>
        </template>

        <template v-slot:[`item.created_on`]="{ item }">
          <span v-if="item.created_on != null">
            {{moment(item.created_on).format($config.timestamp)}}
          </span>
        </template>
        <template v-slot:[`item.updated_on`]="{ item }">
          <span v-if="item.updated_on != null">
            {{moment(item.updated_on).format($config.timestamp)}}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>

      <v-dialog
        v-if="showDialog"
        width="1000px"
        style="max-width: 1000px"
        @click:outside="closeContentEdit()"
        v-model="showDialog">
        <v-card style="max-width: 1000px" flat v-if="showContentEdit">
          <v-card-title>
            <v-icon left>mdi-pencil</v-icon> {{ crud | capitalize}} Relationship
            <v-btn id="closeEdit" icon absolute right @click="closeContentEdit()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row justify-end>
              <v-col>
                <span class="req-diclaimer float-right">* = Required</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <template v-if="relationshipData.from_party.id === value">
                  <v-text-field
                    v-model="relationshipData.from_party.name"
                    label="From Party *"
                    outlined
                    dense
                    readonly
                    disabled>
                  </v-text-field>
                </template>
                <template v-else>
                  <v-autocomplete
                    v-model="relationshipData.from_party"
                    label="From Party *"
                    :items="validPartyOptions"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-no-data
                    return-object
                    :loading="searching"
                    :rules="[required('From Party', relationshipData.from_party.id)]"
                    :search-input.sync="search_from"
                    :disabled="!relationshipTypeSelected">
                    <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.party_type }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  </v-autocomplete>
                </template>
              </v-col>

              <v-col cols="12" sm="4">
                <v-select
                  v-model="relationshipData.relationship_type"
                  label="Relationship Type *"
                  :items="crud === 'update' ? editRelationshipTypes : relationshipTypes"
                  item-text="name"
                  item-value="id"
                  :dense="(relationshipData.relationship_type && relationshipData.relationship_type.from_rel.name === '')"
                  outlined
                  single-line
                  return-object
                  :rules="[required('Relationship Type', relationshipData.relationship_type.from_rel.name)]">
                  <template v-slot:selection="{ item }">
                    <div style="display: grid; grid-auto-rows: auto; grid-row-gap: 4px; margin-left: 5px;">
                      <span>{{ item.from_rel }}<v-icon right small>mdi-arrow-right</v-icon></span>
                      <span><v-icon left small>mdi-arrow-left</v-icon>{{ item.to_rel }}</span>
                    </div>
                  </template>

                  <template v-slot:item="{ item }">
                    <div style="display: grid; grid-auto-rows: auto; padding-top: 5px; padding-bottom: 5px">
                      <span>{{ item.from_rel }}<v-icon right small>mdi-arrow-right</v-icon></span>
                      <span><v-icon left small>mdi-arrow-left</v-icon>{{ item.to_rel }}</span>
                    </div>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="4">
                <template v-if="relationshipData.to_party.id === value">
                  <v-text-field
                    v-model="relationshipData.to_party.name"
                    label="To Party *"
                    outlined
                    dense
                    readonly
                    disabled>
                  </v-text-field>
                </template>
                <template v-else>
                  <v-autocomplete
                    v-model="relationshipData.to_party"
                    label="To Party *"
                    :items="validPartyOptions"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-no-data
                    return-object
                    :loading="searching"
                    :rules="[required('To Party', relationshipData.to_party.id)]"
                    :search-input.sync="search_to"
                    :disabled="!relationshipTypeSelected">
                    <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.party_type }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  </v-autocomplete>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu ref="startDtMenu"
                  v-model="startDtMenu"
                  transition="scale-transition"
                  offset-y
                  required
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="relationshipData.start_dt"
                      label="Relationship Start Date *"
                      type="date"
                      :rules="startDateRules"
                      clearable
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="relationshipData.start_dt"
                    :max="relationshipData.end_dt"
                    :min="partyAttribute.begin_date"
                    no-title
                    scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="startDtMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDtMenu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu ref="endDtMenu"
                  v-model="endDtMenu"
                  transition="scale-transition"
                  offset-y required
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="relationshipData.end_dt"
                      label="Relationship End Date"
                      type="date"
                      :rules="endDateRules"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="relationshipData.end_dt"
                    :min="relationshipData.start_dt"
                    no-title
                    scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDtMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.endDtMenu.save(date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="end">
              <v-col class="d-flex justify-end">
                <v-btn id="saveBtn"
                  color="success"
                  class="mr-4"
                  :disabled="disabled(relationshipData, ['to_party.id', 'relationship_type.id', 'from_party.id', 'start_dt'])"
                  @click="savePartyRelationship">
                  {{ crud }}
                </v-btn>
                <v-btn color="warning" class="mr-4" @click="reset">
                  Reset
                </v-btn>
                <v-btn v-if="hasWriteAccess && crud != 'create'" color="red" class="white--text mr-4" @click="removeItem">
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>
<script>
import Relationship from '@/axios/party-relationship-endpoint'
import Search from '@/axios/search-endpoint'
// third-party
import { cloneDeep, debounce } from 'lodash'

// mixins
import { displayAlert } from '@/mixins/alert'
import { formValidation } from '@/mixins/formvalidation'
import { fullwidth } from '@/mixins/fullwidth'
import { defaultStartDate } from '@/mixins/default-date'
import { userAccess } from '@/mixins/user-access'
export default {
  name: 'partyRelationship',
  data () {
    return {
      crud: 'create',
      pageSize: 50,
      pageSizes: [25, 50, 75],
      page: 1,
      endDtMenu: false,
      searching: false,
      partyRelationships: [],
      editRelationshipTypes: [],
      relationshipDataBase: {
        from_party: {
          name: '',
          id: ''
        },
        relationship_type: {
          to_rel: {
            name: ''
          },
          from_rel: {
            name: ''
          }
        },
        from_party_id: '',
        to_party: {
          name: '',
          id: ''
        },
        to_party_id: '',
        relationship_type_id: '',
        start_dt: new Date().toISOString().substr(0, 10),
        end_dt: null,
        created_by: this.$store.getters.email
      },
      startDateRules: [
        (v) => !!v || 'Relationship Start Date is required',
        (value) => {
          if (value && new Date(value) < new Date('2012-01-01')) {
            return 'Start date must be after 2012-01-01';
          }
          return true;
        },
        (value) => {
          if (this.partyAttribute.begin_date !== null && new Date(value) < new Date(this.partyAttribute.begin_date)) {
            return 'Start date must be equal to the start date or after';
          }
          return true;
        }
      ],
      endDateRules: [
        (value) => {
          if (value && new Date(value) < new Date(this.relationshipData.start_dt)) {
            return 'End Date cannot be before the Start Date';
          }
          return true;
        }
      ],
      relationshipData: {},
      search_from: '',
      search_to: '',
      selected: [],
      showContentEdit: false,
      showDialog: false,
      startDtMenu: false,
      validPartyOptions: [
        /* {
          name: 'Please Select a Relationship Type',
          id: ''
        }
        */
      ],
      select: null,
      search: '',
      selectedRelationshipFilter: null,
      selectedPartyFilter: null,
      partyType: '',
      partyTypeId: '',
      baseHeaders: [
        { text: 'From Party', align: 'start', sortable: true, value: 'from_party' },
        { text: 'Relationship', align: 'start', sortable: true, value: 'relationship_type.from_rel.name' },
        { text: 'To Party', align: 'start', sortable: true, value: 'to_party' },
        { text: 'Start Date', value: 'start_dt' },
        { text: 'End Date', value: 'end_dt' },
        { text: 'Updated On', value: 'updated_on' },
        { text: 'Updated By', value: 'updated_by' }
      ]
    }
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    party: {
      type: Object
    },
    propsPartyTypeId: {
      type: String,
      required: true
    },
    partyAttribute: {
      type: Object
    }
  },
  mixins: [displayAlert, formValidation, fullwidth, defaultStartDate, userAccess],
  watch: {
    search_from: {
      handler: debounce(function(newValue) {
        if (newValue && this.isToParty) {
          this.searchParties()
        }
      }, 500)
    },
    search_to: {
      handler: debounce(function(newValue) {
        if (newValue && !this.isToParty) {
          this.searchParties()
        }
      }, 500)
    },
    'relationshipData.relationship_type.id': {
      handler(newValue) {
        if (newValue && this.crud === 'create') {
          if (this.isToParty && this.relationshipData.to_party?.id !== this.party.id) {
            this.relationshipData.to_party = this.party
            this.relationshipData.from_party = { name: '', id: '' }
          } else if (!this.isToParty && this.relationshipData.from_party?.id !== this.party.id) {
            this.relationshipData.from_party = this.party
            this.relationshipData.to_party = { name: '', id: '' }
          }
          this.searchParties()
        }
      },
      deep: true
    }
  },
  computed: {
    relationshipTypeSelected() {
      return !!this.relationshipData.relationship_type.id;
    },
    relationship_types () {
      return this.$store.getters.relationship_types
    },
    party_types () {
      return this.$store.getters.party_types
    },
    toId () {
      return this.value
    },
    headers () {
      return this.hasWriteAccess
        ? [...this.baseHeaders, { text: 'Actions', value: 'actions' }]
        : this.baseHeaders
    },
    currentPartyRelationships () {
      if (this.selectedRelationshipFilter && this.partyRelationships.length > 0) {
        return this.partyRelationships.filter(rel => {
          return rel.relationship_type.id === this.selectedRelationshipFilter
        })
      }
      else if (this.selectedPartyFilter && this.partyRelationships.length > 0)
      {
        return this.partyRelationships.filter(rel => {
          return (rel.from_party.party_type.id === this.selectedPartyFilter || rel.to_party.party_type.id === this.selectedPartyFilter)
        })
      }
      return this.partyRelationships
    },
    uniquePartyRelTypes () {
      if (this.partyRelationships.length > 0 && this.relationshipTypes.length > 0) {
        const types = this.partyRelationships.map(item => item.relationship_type)
        const uniqueTypes = [...new Map(types.map(type => [type.id, type])).values()]
        return uniqueTypes
      }
      return []
    },
    uniquePartyTypes () {
      if (this.partyRelationships.length > 0 && this.relationshipTypes.length > 0) {
        const fromTypes = this.partyRelationships.map(item => item.from_party.party_type)
        const toTypes = this.partyRelationships.map(item => item.to_party.party_type)
        const types = fromTypes.concat(toTypes)
        const uniqueTypes = [...new Map(types.map(type => [type.id, type])).values()]
        const typesExcludeSelf = uniqueTypes.filter(type => type.id !== this.party.party_type.id)
        return typesExcludeSelf
      }
      return []
    },
    partyRelationshipTypes () {
      if (this.uniquePartyRelTypes.length > 0) {
        return this.uniquePartyRelTypes.map(type => {
          const match = this.relationship_types.find(t => t.id === type.id)
          return this.formatRelationshipType(match)
        })
      }
      return []
    },
    validRelationshipTypes () {
      if (this.relationship_types.length > 0 && this.party.party_type?.id) {
        return this.relationship_types.filter(rel => {
          if (rel.party_relation_rules) {
            const { from_party_type_ids, to_party_type_ids } = rel.party_relation_rules
            let types = []
            if (this.crud === 'update') {
              types = this.isToParty ? to_party_type_ids : from_party_type_ids
            } else if (this.crud === 'create') {
              types = [...from_party_type_ids, ...to_party_type_ids]
            }
            return types.includes(this.party.party_type.id)
          }
        })
      }
      return []
    },
    relationshipTypes () {
      if (this.validRelationshipTypes.length > 0) {
        return this.validRelationshipTypes.map(type => {
          return this.formatRelationshipType(type)
        })
      }
      return []
    },
    validToPartyTypes () {
      if (this.relationshipData.relationship_type?.party_relation_rules) {
        const { to_party_type_ids } = this.relationshipData.relationship_type.party_relation_rules
        const partyTypes = to_party_type_ids.map(id => this.getPartyType(id))
        return partyTypes.map(p => p.constant)
      }
      return []
    },
    validFromPartyTypes () {
      if (this.relationshipData.relationship_type?.party_relation_rules) {
        const { from_party_type_ids } = this.relationshipData.relationship_type.party_relation_rules
        const partyTypes = from_party_type_ids.map(id => this.getPartyType(id))
        return partyTypes.map(p => p.constant)
      }
      return []
    },
    isToParty () {
      if (this.partyType && this.relationshipData.relationship_type?.id) {
        return this.validToPartyTypes.includes(this.partyType)
      }
      return false
    },
    validSearchPartyTypes () {
      return this.isToParty ? this.validFromPartyTypes : this.validToPartyTypes
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  created () {
    this.nonTableHeight = 403
    this.partyTypeId = this.party.party_type.id
    this.partyType = this.party.party_type.constant
    this.initRelationshipList()
  },
  methods: {
    back () {
      this.$router.push({ name: 'Parties' })
    },
    closeContentEdit () {
      this.showDialog = false
      this.showContentEdit = false
      this.editRelationshipTypes = []
      if (document.querySelector('.v-data-table__selected')) {
        document.querySelector('.v-data-table__selected').click()
      }
    },
    createNewRelationship () {
      this.setDefaultStartDate()
      this.resetAlert()
      this.crud = 'create'
      const base = cloneDeep(this.relationshipDataBase)
      this.relationshipData = base
      this.showDialog = true
      this.showContentEdit = true
    },
    async setDefaultStartDate () {
      try {
        const defaultDate = await this.getPartyDefaultStartDate({ partyId: this.value })
        if (defaultDate) {
          this.relationshipData.start_dt = defaultDate
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    editItem (item) {
      this.crud = 'update'
      this.relationshipData = cloneDeep(item)
      this.editRelationshipTypes = [this.formatRelationshipType(this.relationshipData.relationship_type)]
      this.validPartyOptions = [(this.isToParty)
        ? {
          name: item.from_party.name,
          id: item.from_party.id
        }
        : {
          name: item.to_party.name,
          id: item.to_party.id
        }
      ]
      this.showDialog = true
      this.showContentEdit = true
    },
    async removeItem () {
      try {
        await Relationship.delete(this.relationshipData.id)
        this.initRelationshipList()
        this.emitAlert(true, 'success', 'Your party\'s relationship has been successfully Removed!')
        this.closeContentEdit()
        
      } catch (err) {
        this.handleError(err)
      }
    },
    getPartyType (id) {
      return this.party_types.find(type => type.id === id)
    },
    async searchParties () {
      const { operator, term } = this.buildSearchParams()
      if (!term) return
      this.searching = true
      let parties = []
      try {
        const res = await Search.customerSearch(term, operator)
        if (res?.data?.length > 0) {
          parties = res.data.map(party => {
            party.id = party.party_id
            party.name = party.party_name
            if (party.party_type === 'VENDOR' && party.attributes?.VENDOR_ID) {
              party.name = `${party.attributes.VENDOR_ID} ${party.name}`
            } else if (party.party_type === 'STORE' && party.attributes?.WHOLESALER_STORE_NUMBER) {
              party.name = `#${party.attributes.WHOLESALER_STORE_NUMBER} ${party.name}`
            }
            return party
          })
          if (this.validSearchPartyTypes.length > 0) {
            parties = parties.filter(p => this.validSearchPartyTypes.includes(p.party_type))
          }
        }
        this.validPartyOptions = parties.sort(this.sortByPartyName)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.searching = false
      }
    },
    buildSearchParams () {
      const currentSearch = this.isToParty ? this.search_from : this.search_to
      if (currentSearch) {
        return { operator: 'and', term: currentSearch }
      } else {
        return { operator: 'or', term: this.validSearchPartyTypes.join(' ') }
      }
    },
    async initRelationshipList () {
      this.loading = true
      try {
        let payload = {
          get_attributes: true,
        }
        const response = await Relationship.getList(this.toId, 100, 0, payload)
        this.partyRelationships = response.data
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatAttribute (item, party_dir) {
      let attribute = ""  

      if (party_dir == "from")
      {
        attribute += item.from_party.party_type.constant == "STORE" ? "#" : ""
        attribute += item.attributes?.from_attributes?.attr_value ? item.attributes.from_attributes.attr_value : ""
      }
      else if (party_dir == "to")
      {
        attribute += item.to_party.party_type.constant == "STORE" ? "#" : ""
        attribute += item.attributes?.to_attributes?.attr_value ? item.attributes.to_attributes.attr_value : ""
      }

      return attribute
    },
    formatRelationshipType (type) {
      return {
        name: `To: ${type.from_rel.name}, From: ${type.to_rel.name}`,
        id: type.id,
        toConst: type.to_rel.constant,
        fromConst: type.from_rel.constant,
        from_rel: type.from_rel.name,
        to_rel: type.to_rel.name,
        party_relation_rules: type.party_relation_rules
      }
    },
    reset () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.$refs.form.resetValidation()
      this.initRelationshipList()
      this.crud = 'create'
    },
    savePartyRelationship () {
      this.validate()
      this.resetAlert()
      const saveData = cloneDeep(this.relationshipData)

      saveData.from_party_id = cloneDeep(this.relationshipData.from_party.id)
      saveData.to_party_id = cloneDeep(this.relationshipData.to_party.id)
      saveData.relationship_type_id = cloneDeep(this.relationshipData.relationship_type.id)

      delete saveData.from_party
      delete saveData.to_party
      delete saveData.relationship_type
      delete saveData.created_on
      delete saveData.updated_on

      if (this.valid) {
        if (this.crud === 'create') {
          this.saveNew(saveData)
        } else {
          this.updateRelationship(saveData)
        }
      }
    },
    async saveNew (saveData) {
      delete saveData.updated_by
      await Relationship.post(saveData).then(() => {
        this.initRelationshipList()
        this.closeContentEdit()
        this.emitAlert(true, 'success', 'Your party\'s relationship has been successfully created!')
        this.closeContentEdit()
      }).catch(err => {
        this.handleError(err)
      })
    },
    async updateRelationship (saveData) {
      delete saveData.created_by
      await Relationship.put(saveData.id, saveData).then(() => {
        this.initRelationshipList()
        this.emitAlert(true, 'success', 'Your party\'s relationship has been successfully updated!')
        this.closeContentEdit()
      }).catch(err => {
        this.handleError(err)
      })
    },
    sortByPartyName(a, b) {
      if (a.name) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
    },
    clearSelectedPartyFilter () {
      this.selectedPartyFilter = null
    },
    clearSelectedRelationshipFilter () {
      this.selectedRelationshipFilter = null
    }
  }
}
</script>
